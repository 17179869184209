import { useState, useEffect } from 'react'
import { Link, NavLink, useNavigate, useSearchParams, createSearchParams, URLSearchParamsInit } from "react-router-dom"
import { observer } from 'mobx-react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

import { userStore } from '../stores/user'
import { api } from '../api'
import { alertStore } from "../stores/error"
import { Project, ProjectList } from '../zzgen'
import 'bootstrap/dist/css/bootstrap.min.css'

export const TopPanel = observer(function TopPanel() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [projects, setProjects] = useState<Project[]>([])

  const refreshProjects = () => {
    api.projectList().then((res: ProjectList) => {
      setProjects(res.items)
    })
  }

  const logout = async () => {
    try {
      await api.authLoginDelete()

      alertStore.clearError()
      userStore.logout()
      navigate(`/`)
    } catch { }
  }

  const pid = Number(searchParams.get("pid"))

  useEffect(() => {
    refreshProjects()
  }, [setProjects])

  const setProject = (pid: number) => {
    let params: URLSearchParamsInit = {}
    if (pid > 0) {
      params["pid"] = String(pid)
    }

    setSearchParams(params)
  }

  let projectTitle = "[Все задачи]"
  for (let p of projects) {
    if (p.id === pid) {
      projectTitle = p.title
    }
  }

  let params: URLSearchParamsInit = {}
  if (pid > 0) {
    params["pid"] = String(pid)
  }

  const searchString = createSearchParams(params).toString()

  return (<Navbar bg="light" expand="lg">
    <Container>
      <Navbar.Brand><Link to={{
        pathname: "",
        search: searchString
      }} style={{ color: "gray" }}>Tasks</Link></Navbar.Brand>
      <Nav className="me-auto">
        <Nav.Link as={NavLink} to={{
          pathname: "/todo",
          search: searchString
        }}>ToDo</Nav.Link>
      </Nav>
      <Nav className="me-auto">
        <Nav.Link as={NavLink} to={{
          pathname: "/projects",
          search: searchString
        }}>Projects</Nav.Link>
      </Nav>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      </Navbar.Collapse>
      <NavDropdown title={projectTitle}>
        <NavDropdown.Item onClick={() => { setProject(0) }}>[Все задачи]</NavDropdown.Item>
        {projects.map(p => <NavDropdown.Item onClick={() => { setProject(p.id) }}>{p.title}</NavDropdown.Item>)}
      </NavDropdown>
      <Nav className="me-auto">
        {userStore.uid === 0 ?
          <>
            <Nav.Link as={NavLink} to="/login">Вход</Nav.Link>
            <Nav.Link as={NavLink} to="/register">Регистрация</Nav.Link>
          </> :
          <>
            <NavDropdown title={userStore.email} id="basic-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="/profile">Профиль</NavDropdown.Item>
              <NavDropdown.Item onClick={logout}>Выйти</NavDropdown.Item>
            </NavDropdown>
          </>
        }
      </Nav>
    </Container>
  </Navbar>)
})