/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthChangePasswordPostRequest,
  AuthLoginPost200Response,
  AuthLoginPostRequest,
  AuthRefreshPost200Response,
  AuthRegisterPost200Response,
  AuthRegisterPostRequest,
  AuthRegisterUidActivatePostRequest,
  AuthResetPasswordInitPostRequest,
  AuthResetPasswordPostRequest,
  CurrentUser,
  CurrentUserPutRequest,
  IntOptionsItem,
  ProjectCreateReq,
  ProjectCreateResp,
  ProjectList,
  ProjectUpdateReq,
  TaskCreateReq,
  TaskCreateResp,
  TaskDepCreateReq,
  TaskInfo,
  TaskList,
  TaskStatusUpdateReq,
  TaskUpdateReq,
} from '../models/index';
import {
    AuthChangePasswordPostRequestFromJSON,
    AuthChangePasswordPostRequestToJSON,
    AuthLoginPost200ResponseFromJSON,
    AuthLoginPost200ResponseToJSON,
    AuthLoginPostRequestFromJSON,
    AuthLoginPostRequestToJSON,
    AuthRefreshPost200ResponseFromJSON,
    AuthRefreshPost200ResponseToJSON,
    AuthRegisterPost200ResponseFromJSON,
    AuthRegisterPost200ResponseToJSON,
    AuthRegisterPostRequestFromJSON,
    AuthRegisterPostRequestToJSON,
    AuthRegisterUidActivatePostRequestFromJSON,
    AuthRegisterUidActivatePostRequestToJSON,
    AuthResetPasswordInitPostRequestFromJSON,
    AuthResetPasswordInitPostRequestToJSON,
    AuthResetPasswordPostRequestFromJSON,
    AuthResetPasswordPostRequestToJSON,
    CurrentUserFromJSON,
    CurrentUserToJSON,
    CurrentUserPutRequestFromJSON,
    CurrentUserPutRequestToJSON,
    IntOptionsItemFromJSON,
    IntOptionsItemToJSON,
    ProjectCreateReqFromJSON,
    ProjectCreateReqToJSON,
    ProjectCreateRespFromJSON,
    ProjectCreateRespToJSON,
    ProjectListFromJSON,
    ProjectListToJSON,
    ProjectUpdateReqFromJSON,
    ProjectUpdateReqToJSON,
    TaskCreateReqFromJSON,
    TaskCreateReqToJSON,
    TaskCreateRespFromJSON,
    TaskCreateRespToJSON,
    TaskDepCreateReqFromJSON,
    TaskDepCreateReqToJSON,
    TaskInfoFromJSON,
    TaskInfoToJSON,
    TaskListFromJSON,
    TaskListToJSON,
    TaskStatusUpdateReqFromJSON,
    TaskStatusUpdateReqToJSON,
    TaskUpdateReqFromJSON,
    TaskUpdateReqToJSON,
} from '../models/index';

export interface AuthChangePasswordPostOperationRequest {
    req: AuthChangePasswordPostRequest;
}

export interface AuthLoginPostOperationRequest {
    req: AuthLoginPostRequest;
}

export interface AuthRegisterPostOperationRequest {
    req: AuthRegisterPostRequest;
}

export interface AuthRegisterUidActivatePostOperationRequest {
    uid: number;
    req: AuthRegisterUidActivatePostRequest;
}

export interface AuthResetPasswordInitPostOperationRequest {
    req: AuthResetPasswordInitPostRequest;
}

export interface AuthResetPasswordPostOperationRequest {
    req: AuthResetPasswordPostRequest;
}

export interface CurrentUserPutOperationRequest {
    req: CurrentUserPutRequest;
}

export interface DepCreateRequest {
    taskID: number;
    req: TaskDepCreateReq;
}

export interface DepCreateOptionsRequest {
    taskID: number;
}

export interface DepDeleteRequest {
    taskID: number;
    depID: number;
}

export interface ProjectCreateRequest {
    req: ProjectCreateReq;
}

export interface ProjectDeleteRequest {
    projectID: number;
}

export interface ProjectUpdateRequest {
    projectID: number;
    req: ProjectUpdateReq;
}

export interface TaskUpdateRequest {
    taskID: number;
    req: TaskUpdateReq;
}

export interface TasksGetRequest {
    pid?: number;
    hideResolvedBefore?: Date;
}

export interface TasksPostRequest {
    req: TaskCreateReq;
}

export interface TasksTaskIDDeleteRequest {
    taskID: number;
}

export interface TasksTaskIDGetRequest {
    taskID: number;
}

export interface TasksTaskIDStatusPostRequest {
    taskID: number;
    req: TaskStatusUpdateReq;
}

export interface TodoGetRequest {
    pid?: number;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async authChangePasswordPostRaw(requestParameters: AuthChangePasswordPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling authChangePasswordPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/auth/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthChangePasswordPostRequestToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authChangePasswordPost(requestParameters: AuthChangePasswordPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authChangePasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authLoginDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/login`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authLoginDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authLoginDeleteRaw(initOverrides);
    }

    /**
     */
    async authLoginPostRaw(requestParameters: AuthLoginPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthLoginPost200Response>> {
        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling authLoginPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthLoginPostRequestToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthLoginPost200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async authLoginPost(requestParameters: AuthLoginPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthLoginPost200Response> {
        const response = await this.authLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * refresh access token (access_token cookie) using refresh token (refresh_token cookie)
     */
    async authRefreshPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthRefreshPost200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthRefreshPost200ResponseFromJSON(jsonValue));
    }

    /**
     * refresh access token (access_token cookie) using refresh token (refresh_token cookie)
     */
    async authRefreshPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthRefreshPost200Response> {
        const response = await this.authRefreshPostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authRegisterPostRaw(requestParameters: AuthRegisterPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthRegisterPost200Response>> {
        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling authRegisterPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthRegisterPostRequestToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthRegisterPost200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async authRegisterPost(requestParameters: AuthRegisterPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthRegisterPost200Response> {
        const response = await this.authRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authRegisterUidActivatePostRaw(requestParameters: AuthRegisterUidActivatePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uid'] == null) {
            throw new runtime.RequiredError(
                'uid',
                'Required parameter "uid" was null or undefined when calling authRegisterUidActivatePost().'
            );
        }

        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling authRegisterUidActivatePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/register/{uid}/activate`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters['uid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthRegisterUidActivatePostRequestToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authRegisterUidActivatePost(requestParameters: AuthRegisterUidActivatePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authRegisterUidActivatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authResetPasswordInitPostRaw(requestParameters: AuthResetPasswordInitPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling authResetPasswordInitPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset-password-init`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthResetPasswordInitPostRequestToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authResetPasswordInitPost(requestParameters: AuthResetPasswordInitPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authResetPasswordInitPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authResetPasswordPostRaw(requestParameters: AuthResetPasswordPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling authResetPasswordPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthResetPasswordPostRequestToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authResetPasswordPost(requestParameters: AuthResetPasswordPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authResetPasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async currentUserGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CurrentUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/current-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrentUserFromJSON(jsonValue));
    }

    /**
     */
    async currentUserGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CurrentUser> {
        const response = await this.currentUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async currentUserPutRaw(requestParameters: CurrentUserPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling currentUserPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/current-user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CurrentUserPutRequestToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async currentUserPut(requestParameters: CurrentUserPutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.currentUserPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async depCreateRaw(requestParameters: DepCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['taskID'] == null) {
            throw new runtime.RequiredError(
                'taskID',
                'Required parameter "taskID" was null or undefined when calling depCreate().'
            );
        }

        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling depCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks/{taskID}/deps`.replace(`{${"taskID"}}`, encodeURIComponent(String(requestParameters['taskID']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskDepCreateReqToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async depCreate(requestParameters: DepCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.depCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async depCreateOptionsRaw(requestParameters: DepCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IntOptionsItem>>> {
        if (requestParameters['taskID'] == null) {
            throw new runtime.RequiredError(
                'taskID',
                'Required parameter "taskID" was null or undefined when calling depCreateOptions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks/{taskID}/dep-create-options`.replace(`{${"taskID"}}`, encodeURIComponent(String(requestParameters['taskID']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IntOptionsItemFromJSON));
    }

    /**
     */
    async depCreateOptions(requestParameters: DepCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IntOptionsItem>> {
        const response = await this.depCreateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async depDeleteRaw(requestParameters: DepDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['taskID'] == null) {
            throw new runtime.RequiredError(
                'taskID',
                'Required parameter "taskID" was null or undefined when calling depDelete().'
            );
        }

        if (requestParameters['depID'] == null) {
            throw new runtime.RequiredError(
                'depID',
                'Required parameter "depID" was null or undefined when calling depDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks/{taskID}/deps/{depID}`.replace(`{${"taskID"}}`, encodeURIComponent(String(requestParameters['taskID']))).replace(`{${"depID"}}`, encodeURIComponent(String(requestParameters['depID']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async depDelete(requestParameters: DepDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.depDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectCreateRaw(requestParameters: ProjectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectCreateResp>> {
        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling projectCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectCreateReqToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCreateRespFromJSON(jsonValue));
    }

    /**
     */
    async projectCreate(requestParameters: ProjectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectCreateResp> {
        const response = await this.projectCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectDeleteRaw(requestParameters: ProjectDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectID'] == null) {
            throw new runtime.RequiredError(
                'projectID',
                'Required parameter "projectID" was null or undefined when calling projectDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/projects/{projectID}`.replace(`{${"projectID"}}`, encodeURIComponent(String(requestParameters['projectID']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectDelete(requestParameters: ProjectDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async projectListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectListFromJSON(jsonValue));
    }

    /**
     */
    async projectList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectList> {
        const response = await this.projectListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async projectUpdateRaw(requestParameters: ProjectUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['projectID'] == null) {
            throw new runtime.RequiredError(
                'projectID',
                'Required parameter "projectID" was null or undefined when calling projectUpdate().'
            );
        }

        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling projectUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/projects/{projectID}`.replace(`{${"projectID"}}`, encodeURIComponent(String(requestParameters['projectID']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateReqToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectUpdate(requestParameters: ProjectUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async taskUpdateRaw(requestParameters: TaskUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['taskID'] == null) {
            throw new runtime.RequiredError(
                'taskID',
                'Required parameter "taskID" was null or undefined when calling taskUpdate().'
            );
        }

        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling taskUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks/{taskID}`.replace(`{${"taskID"}}`, encodeURIComponent(String(requestParameters['taskID']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaskUpdateReqToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async taskUpdate(requestParameters: TaskUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.taskUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tasksGetRaw(requestParameters: TasksGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaskList>> {
        const queryParameters: any = {};

        if (requestParameters['pid'] != null) {
            queryParameters['pid'] = requestParameters['pid'];
        }

        if (requestParameters['hideResolvedBefore'] != null) {
            queryParameters['hide_resolved_before'] = (requestParameters['hideResolvedBefore'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskListFromJSON(jsonValue));
    }

    /**
     */
    async tasksGet(requestParameters: TasksGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaskList> {
        const response = await this.tasksGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tasksPostRaw(requestParameters: TasksPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaskCreateResp>> {
        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling tasksPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskCreateReqToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskCreateRespFromJSON(jsonValue));
    }

    /**
     */
    async tasksPost(requestParameters: TasksPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaskCreateResp> {
        const response = await this.tasksPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tasksTaskIDDeleteRaw(requestParameters: TasksTaskIDDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['taskID'] == null) {
            throw new runtime.RequiredError(
                'taskID',
                'Required parameter "taskID" was null or undefined when calling tasksTaskIDDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks/{taskID}`.replace(`{${"taskID"}}`, encodeURIComponent(String(requestParameters['taskID']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tasksTaskIDDelete(requestParameters: TasksTaskIDDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tasksTaskIDDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tasksTaskIDGetRaw(requestParameters: TasksTaskIDGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaskInfo>> {
        if (requestParameters['taskID'] == null) {
            throw new runtime.RequiredError(
                'taskID',
                'Required parameter "taskID" was null or undefined when calling tasksTaskIDGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks/{taskID}`.replace(`{${"taskID"}}`, encodeURIComponent(String(requestParameters['taskID']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskInfoFromJSON(jsonValue));
    }

    /**
     */
    async tasksTaskIDGet(requestParameters: TasksTaskIDGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaskInfo> {
        const response = await this.tasksTaskIDGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tasksTaskIDStatusPostRaw(requestParameters: TasksTaskIDStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['taskID'] == null) {
            throw new runtime.RequiredError(
                'taskID',
                'Required parameter "taskID" was null or undefined when calling tasksTaskIDStatusPost().'
            );
        }

        if (requestParameters['req'] == null) {
            throw new runtime.RequiredError(
                'req',
                'Required parameter "req" was null or undefined when calling tasksTaskIDStatusPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/tasks/{taskID}/status`.replace(`{${"taskID"}}`, encodeURIComponent(String(requestParameters['taskID']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskStatusUpdateReqToJSON(requestParameters['req']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tasksTaskIDStatusPost(requestParameters: TasksTaskIDStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tasksTaskIDStatusPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async todoGetRaw(requestParameters: TodoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaskList>> {
        const queryParameters: any = {};

        if (requestParameters['pid'] != null) {
            queryParameters['pid'] = requestParameters['pid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["cookie"] = await this.configuration.apiKey("cookie"); // cookie authentication
        }

        const response = await this.request({
            path: `/todo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskListFromJSON(jsonValue));
    }

    /**
     */
    async todoGet(requestParameters: TodoGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaskList> {
        const response = await this.todoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
