/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntOptionsItem
 */
export interface IntOptionsItem {
    /**
     * 
     * @type {string}
     * @memberof IntOptionsItem
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof IntOptionsItem
     */
    value: number;
}

/**
 * Check if a given object implements the IntOptionsItem interface.
 */
export function instanceOfIntOptionsItem(value: object): value is IntOptionsItem {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function IntOptionsItemFromJSON(json: any): IntOptionsItem {
    return IntOptionsItemFromJSONTyped(json, false);
}

export function IntOptionsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntOptionsItem {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
        'value': json['value'],
    };
}

  export function IntOptionsItemToJSON(json: any): IntOptionsItem {
      return IntOptionsItemToJSONTyped(json, false);
  }

  export function IntOptionsItemToJSONTyped(value?: IntOptionsItem | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'value': value['value'],
    };
}

