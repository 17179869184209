import { useNavigate } from "react-router-dom"
import { Button, Form } from 'react-bootstrap'

import { useInput } from '../utils'
import { api } from '../api'
import { alertStore } from "../stores/error"

export function ResetPasswordInitPage() {
    const navigate = useNavigate()
    const { value: email, bind: bindEmail } = useInput('')

    const submit = async (e: any) => {
        e.preventDefault()

        try {
            await api.authResetPasswordInitPost({
                req: {
                    email: email,
                }
            })

            alertStore.clearError()
            alertStore.setSuccess("Проверьте вашу почту и перейдите по ссылке из письма")
            navigate(`/login`)
        } catch { }
    }

    return (<div style={{ width: "500px", margin: "auto", paddingTop: "30px" }}>
        <h1>Забыли ваш пароль?</h1>
        <Form onSubmit={submit}>
            <Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Введите ваш email и мы пришлем вам ссылку для сброса пароля</Form.Label>
                    <Form.Control {...bindEmail} placeholder="Email Address" />
                </Form.Group>
            </Form.Group>
            <Form.Group className="mb-3">
                <Button variant="primary" type="submit">
                    Послать ссылку для сброса пароля
                </Button>
            </Form.Group>
        </Form>

    </div>)
}