import './index.css'
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from './App'

import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'

const rootElement = document.getElementById('root')
if (!rootElement)
  throw new Error('Failed to find the root element')

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
