import { useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Row, Col, Button, Form } from 'react-bootstrap'

import { useInput, validatePassword } from '../utils'
import { api } from '../api'
import { alertStore } from "../stores/error"


export function ResetPasswordPage() {
    const navigate = useNavigate()
    const { code } = useParams()
    const { value: newPassword1, bind: bindNewPassword1 } = useInput("", () => { setPasswordFeedback("") })
    const { value: newPassword2, bind: bindNewPassword2 } = useInput("", () => { setPasswordFeedback("") })
    const [passwordFeedback, setPasswordFeedback] = useState("")

    if (!code) {
        navigate("/login")
    }

    const submit = (e: any) => {
        e.preventDefault()

        if (newPassword1 !== newPassword2) {
            setPasswordFeedback("пароли не совпадают")
            return
        }

        if (!validatePassword(newPassword1)) {
            setPasswordFeedback("пароль должен быть не короче 8 символов и содержать большие и маленькие буквы, цифры и специальные символы")
            return
        }

        api.authResetPasswordPost({
            req: {
                code: code,
                newPassword: newPassword1,
            }
        }).then(() => {
            alertStore.clearError()
            alertStore.setSuccess("Пароль успешно обновлён.")
            navigate(`/login`)
        }).catch((e) => {
            alertStore.setError(e.response.data.msg ? e.response.data.msg : e.response.data)
        })
    }

    return (<div style={{ width: "1100px", margin: "auto", paddingTop: "30px" }}>
        <><h2>Сменить пароль</h2>
            <Form onSubmit={submit}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">Новый пароль</Form.Label>
                    <Col sm="9"><Form.Control type="password" placeholder="Новый пароль" {...bindNewPassword1} /></Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="3">Повторите новый пароль</Form.Label>
                    <Col sm="9">
                        <Form.Control isInvalid={passwordFeedback !== ""} type="password" placeholder="Повторите новый пароль" {...bindNewPassword2} />
                        <Form.Control.Feedback type="invalid">{passwordFeedback}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Button onClick={submit} variant="primary" disabled={!(newPassword1 && newPassword2)}>Сменить пароль</Button>
            </Form>
        </>

    </div>)
}