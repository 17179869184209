import { useEffect, useState } from 'react'
import { Button, Form, Col, Row } from 'react-bootstrap'

import { alertStore } from '../stores/error'
import { api } from '../api'
import { useInput, validatePassword } from '../utils'
import { AuthChangePasswordPostRequest } from '../zzgen'

export function ProfileEditPage() {
    return <>
        <UserUpdate />
        <PasswordChange />
    </>
}

function UserUpdate() {
    const { value: email, bind: bindEmail, setValue: setEmail } = useInput("")
    const [emailFeedback, setEmailFeedback] = useState("")

    useEffect(() => {
        api.currentUserGet().then((r) => {
            setEmail(r.email)
        })
    }, [setEmail])

    const submit = async (e: any) => {
        e.preventDefault()

        alertStore.clearError()
        alertStore.clearSuccess()
        setEmail("")


        if (email.length < 3) {
            setEmailFeedback("email должно быть не короче трех символов")
            return
        }
        if (email.length > 128) {
            setEmailFeedback("email должно быть не длиннее 128 символов")
        }

        try {
            await api.currentUserPut({
                req: {
                    email: email,
                },
            })

            alertStore.clearError()
            alertStore.setSuccess("Свойства профиля успешно сохранены.")
        } catch { }
    }

    return <>
        <h2>Поменять свойства профиля</h2>
        <Form onSubmit={submit} className="mb-5">
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Имя</Form.Label>
                <Col sm="9">
                    <Form.Control isInvalid={emailFeedback !== ""} placeholder="Имя" {...bindEmail} />
                    <Form.Control.Feedback type="invalid">{emailFeedback}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Button onClick={submit} variant="primary">Сохранить</Button>
        </Form>
    </>
}

function PasswordChange() {
    const { value: currentPassword, bind: bindCurrentPassword, reset: resetCurrentPassword } = useInput("")
    const { value: newPassword1, bind: bindNewPassword1, reset: resetNewPassword1 } = useInput("", () => { setPasswordFeedback("") })
    const { value: newPassword2, bind: bindNewPassword2, reset: resetNewPassword2 } = useInput("", () => { setPasswordFeedback("") })
    const [passwordFeedback, setPasswordFeedback] = useState("")

    const submit = async (e: any) => {
        e.preventDefault()

        alertStore.clearError()
        alertStore.clearSuccess()
        setPasswordFeedback("")

        if (newPassword1 !== newPassword2) {
            setPasswordFeedback("пароли не совпадают")
            return
        }

        if (newPassword1 === currentPassword) {
            setPasswordFeedback("новый пароль не отличается от старого")
            return
        }

        if (!validatePassword(newPassword1)) {
            setPasswordFeedback("пароль должен быть не короче 8 символов и содержать большие и маленькие буквы, цифры и специальные символы")
            return
        }

        let req: AuthChangePasswordPostRequest = {
            currentPassword: currentPassword,
            newPassword: newPassword1
        }

        try {
            await api.authChangePasswordPost({
                req: req,
            })

            alertStore.clearError()
            alertStore.setSuccess("Пароль успешно обновлён.")

            resetCurrentPassword()
            resetNewPassword1()
            resetNewPassword2()
        } catch { }
    }

    return <><h2>Сменить пароль</h2>
        <Form onSubmit={submit}>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Старый пароль</Form.Label>
                <Col sm="9"><Form.Control type="password" placeholder="Старый пароль" {...bindCurrentPassword} /></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Новый пароль</Form.Label>
                <Col sm="9"><Form.Control type="password" placeholder="Новый пароль" {...bindNewPassword1} /></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Повторите новый пароль</Form.Label>
                <Col sm="9">
                    <Form.Control isInvalid={passwordFeedback !== ""} type="password" placeholder="Повторите новый пароль" {...bindNewPassword2} />
                    <Form.Control.Feedback type="invalid">{passwordFeedback}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Button onClick={submit} variant="primary" disabled={!(currentPassword && newPassword1 && newPassword2)}>Сменить пароль</Button>
        </Form>
    </>
}