import { useState, useEffect } from 'react'
import { Button, Modal, Form, Dropdown } from 'react-bootstrap'
import { Link } from "react-router-dom"

import { api } from '../api'
import { useInput, useInputInteger, DotsToggle } from '../utils'
import { alertStore } from "../stores/error"
import { Project, ProjectList, ProjectCreateReq, ProjectUpdateReq } from '../zzgen'

export function ProjectsPage() {
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [projects, setProjects] = useState<Project[]>([])

    const refreshProjects = () => {
        api.projectList().then((res: ProjectList) => {
            setProjects(res.items)
        })
    }

    useEffect(() => {
        refreshProjects()
    }, [setProjects])

    return (<div>
        <Button onClick={() => { setShowCreateModal(true) }}>Создать</Button>

        {showCreateModal &&
            <ProjectCreateModal onHide={() => { setShowCreateModal(false); refreshProjects(); }}
            />
        }

        <ProjectListBlock projects={projects} updateProjects={refreshProjects} />
    </div>)
}

type ProjectListBlockProps = {
    projects: Project[]
    updateProjects: () => void
}

export function ProjectListBlock(props: ProjectListBlockProps) {
    return (<div>
        {props.projects.map((project, idx) =>
            <ProjectCard
            key={project.id}
            project={project}
            updateProjects={props.updateProjects} />)}
    </div>)
}

type ProjectCardProps = {
    project: Project
    updateProjects: () => void
}

function ProjectCard(props: ProjectCardProps) {
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showProjectUpdateModal, setShowProjectUpdateModal] = useState(false)

    const deleteProject = () => {
        api.projectDelete({
            projectID: props.project.id
        }).then(() => {
            props.updateProjects()
        })
    }

    return <div style={{ position: "relative", top: 0, left: 0 }}>
        <Link to={`/projects/${props.project.id}`}>{props.project.title}</Link><br />
        <div style={{ position: "absolute", top: "0px", right: "4px", padding: "0px" }}>
            <Dropdown>
                <Dropdown.Toggle as={DotsToggle} />
                <Dropdown.Menu title="">
                    <Dropdown.Item onClick={() => setShowProjectUpdateModal(true)}>Редактировать</Dropdown.Item>
                    <Dropdown.Item onClick={deleteProject}>Удалить</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>

        {showCreateModal && <ProjectCreateModal
            parentId={props.project.id}
            onHide={() => { setShowCreateModal(false); props.updateProjects(); }}
        />}

        {showProjectUpdateModal && <ProjectUpdateModal
            project={props.project}
            onHide={() => { setShowProjectUpdateModal(false); props.updateProjects(); }}
        />}

    </div>
}

type ProjectCreateModalProps = {
    parentId?: number
    onHide: () => void
}

export function ProjectCreateModal(props: ProjectCreateModalProps) {
    const { value: title, bind: bindTitle } = useInput("")
    const { value: sortOrder, bind: bindSortOrder } = useInputInteger(0)

    const submit = async (e: any) => {
        e.preventDefault()

        let req: ProjectCreateReq = {
            title: title,
            sortOrder: sortOrder,
        }

        try {
            await api.projectCreate({
                req: req,
            })

            alertStore.clearError()
        } catch { }

        props.onHide()
    }

    return <Modal show={true} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Создать проект</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={submit}>
                <Form.Group className="mb-2">
                    <Form.Label>Название</Form.Label>
                    <Form.Control {...bindTitle} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Порядок сортировки</Form.Label>
                    <Form.Control type="number" {...bindSortOrder} />
                </Form.Group>
            </Form>
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={submit} variant="primary">Создать</Button>
        </Modal.Footer>
    </Modal>
}

type ProjectUpdateModalProps = {
    project: Project
    onHide: () => void
}

export function ProjectUpdateModal(props: ProjectUpdateModalProps) {
    const project = props.project
    const { value: title, bind: bindTitle } = useInput(project.title)
    const { value: sortOrder, bind: bindSortOrder } = useInputInteger(project.sortOrder)

    const submit = async (e: any) => {
        e.preventDefault()

        let req: ProjectUpdateReq = {
            title: title,
            sortOrder: sortOrder,
        }

        try {
            await api.projectUpdate({
                projectID: project.id,
                req: req,
            })

            alertStore.clearError()
        } catch (e) { alert(e) }

        props.onHide()
    }

    return <Modal show={true} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Редактировать задачу</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={submit}>
                <Form.Group className="mb-2">
                    <Form.Label>Заголовок</Form.Label>
                    <Form.Control {...bindTitle} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control type="number" {...bindSortOrder} />
                </Form.Group>
            </Form>
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={submit} variant="primary">Сохранить</Button>
        </Modal.Footer>
    </Modal>
}