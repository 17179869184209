import { useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { Alert, Button, Form } from 'react-bootstrap'
import { useInput } from '../utils'
import { api } from '../api'
import { alertStore } from "../stores/error";

export function RegisterActivatePage() {
    const { uid } = useParams()
    const navigate = useNavigate()
    const { value: code, bind: bindCode } = useInput('')
    const [error, setError] = useState(null)

    if (!uid) {
        navigate(-1)
    }

    if (!/\d+/.test((uid as string))) {
        navigate(-1)
    }
    const uidVal: number = +(uid as string)

    const submit = (e: any) => {
        e.preventDefault()

        api.authRegisterUidActivatePost({
            uid: uidVal,
            req: {
                code: code,
            }
        }).then(() => {
            setError(null)
            alertStore.setSuccess("Регистрация успешно завершена!")
            navigate(`/login`)
        }).catch((e) => {
            setError(e.response.data.msg ? e.response.data.msg : e.response.data)
        })
    }

    return (<div style={{ width: "1100px", margin: "auto", paddingTop: "30px" }}>
        {error && <Alert variant="danger">{String(error)}</Alert>}
        <h1>Регистрация</h1>
        <Form onSubmit={submit}>
            <Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Код активации</Form.Label>
                    <Form.Control {...bindCode} />
                </Form.Group>
            </Form.Group>
            <Form.Group className="mb-3">
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form.Group>
        </Form>

    </div>)
}