/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthRefreshPost200Response
 */
export interface AuthRefreshPost200Response {
    /**
     * 
     * @type {number}
     * @memberof AuthRefreshPost200Response
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthRefreshPost200Response
     */
    readonly email: string;
    /**
     * 
     * @type {number}
     * @memberof AuthRefreshPost200Response
     */
    maxAge: number;
}

/**
 * Check if a given object implements the AuthRefreshPost200Response interface.
 */
export function instanceOfAuthRefreshPost200Response(value: object): value is AuthRefreshPost200Response {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('maxAge' in value) || value['maxAge'] === undefined) return false;
    return true;
}

export function AuthRefreshPost200ResponseFromJSON(json: any): AuthRefreshPost200Response {
    return AuthRefreshPost200ResponseFromJSONTyped(json, false);
}

export function AuthRefreshPost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthRefreshPost200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'maxAge': json['maxAge'],
    };
}

  export function AuthRefreshPost200ResponseToJSON(json: any): AuthRefreshPost200Response {
      return AuthRefreshPost200ResponseToJSONTyped(json, false);
  }

  export function AuthRefreshPost200ResponseToJSONTyped(value?: Omit<AuthRefreshPost200Response, 'id'|'email'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'maxAge': value['maxAge'],
    };
}

