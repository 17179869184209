/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
    TaskToJSONTyped,
} from './Task';

/**
 * 
 * @export
 * @interface TaskList
 */
export interface TaskList {
    /**
     * 
     * @type {number}
     * @memberof TaskList
     */
    total: number;
    /**
     * 
     * @type {Array<Task>}
     * @memberof TaskList
     */
    items: Array<Task>;
}

/**
 * Check if a given object implements the TaskList interface.
 */
export function instanceOfTaskList(value: object): value is TaskList {
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function TaskListFromJSON(json: any): TaskList {
    return TaskListFromJSONTyped(json, false);
}

export function TaskListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskList {
    if (json == null) {
        return json;
    }
    return {
        
        'total': json['total'],
        'items': ((json['items'] as Array<any>).map(TaskFromJSON)),
    };
}

  export function TaskListToJSON(json: any): TaskList {
      return TaskListToJSONTyped(json, false);
  }

  export function TaskListToJSONTyped(value?: TaskList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'total': value['total'],
        'items': ((value['items'] as Array<any>).map(TaskToJSON)),
    };
}

