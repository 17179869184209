/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskCreateReq
 */
export interface TaskCreateReq {
    /**
     * 
     * @type {number}
     * @memberof TaskCreateReq
     */
    projectID: number;
    /**
     * 
     * @type {string}
     * @memberof TaskCreateReq
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCreateReq
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof TaskCreateReq
     */
    parentID?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TaskCreateReq
     */
    startsAt?: Date | null;
}

/**
 * Check if a given object implements the TaskCreateReq interface.
 */
export function instanceOfTaskCreateReq(value: object): value is TaskCreateReq {
    if (!('projectID' in value) || value['projectID'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function TaskCreateReqFromJSON(json: any): TaskCreateReq {
    return TaskCreateReqFromJSONTyped(json, false);
}

export function TaskCreateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskCreateReq {
    if (json == null) {
        return json;
    }
    return {
        
        'projectID': json['projectID'],
        'title': json['title'],
        'description': json['description'],
        'parentID': json['parentID'] == null ? undefined : json['parentID'],
        'startsAt': json['startsAt'] == null ? undefined : (new Date(json['startsAt'])),
    };
}

  export function TaskCreateReqToJSON(json: any): TaskCreateReq {
      return TaskCreateReqToJSONTyped(json, false);
  }

  export function TaskCreateReqToJSONTyped(value?: TaskCreateReq | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'projectID': value['projectID'],
        'title': value['title'],
        'description': value['description'],
        'parentID': value['parentID'],
        'startsAt': value['startsAt'] == null ? undefined : ((value['startsAt'] as any).toISOString()),
    };
}

