import { DefaultApi, Configuration, ErrorContext, ResponseContext, BaseError, instanceOfBaseError } from "./zzgen"
import config from "./config"
import { alertStore } from './stores/error'

class ErrorHandler {
    async onError(context: ErrorContext): Promise<Response | void> {
        alertStore.setError("Проблемы с подключением к серверу")
        return context.response
    }

    async post(context: ResponseContext): Promise<Response | void> {
        if (context.response.status >= 200 && context.response.status < 300) {
            return context.response
        }

        let r = await context.response.json() as BaseError
        if (instanceOfBaseError(r)) {
            if (r.message) {
                alertStore.setError(r.message)
            } else {
                alertStore.setError("error with an empty message")
            }
        } else {
            alertStore.setError(JSON.stringify(r))
        }
        
        return context.response
    }
}

export const api = new DefaultApi(new Configuration({
    basePath: config.apiURL,
    middleware: [new ErrorHandler()],
    credentials: 'include'
}
))