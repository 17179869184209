import { useState, useEffect } from 'react'
import { Button, Modal, Form, Dropdown } from 'react-bootstrap'
import { Link } from "react-router-dom"
import classNames from 'classnames'

import { api } from '../api'
import { useInput, DotsToggle } from '../utils'
import { formatDate } from '../utils2'
import { alertStore } from "../stores/error"
import { Task, TaskStatusUpdateReq, IntOptionsItem, DepCreateRequest } from '../zzgen'
import { TaskCreateModal } from './TaskCreateModal'
import { TaskUpdateModal } from './TaskUpdateModal'
import "./TaskListBlock.css"

type contextMenuItem = {
    title: string
    onClick: (task: Task) => void
}

type TaskListBlockProps = {
    tasks: Task[]
    updateTasks: () => void
    extraContextMenu?: contextMenuItem[]
}

export function TaskListBlock(props: TaskListBlockProps) {

    return (<div>
        {props.tasks.map((task, idx) =>
            <TaskCard
                key={task.id}
                task={task}
                updateTasks={props.updateTasks}
                isSubtask={false}
                extraContextMenu={props.extraContextMenu} />)}
    </div>)
}

type TaskCardProps = {
    task: Task
    updateTasks: () => void
    isSubtask: boolean
    extraContextMenu?: contextMenuItem[]
}

function TaskCard(props: TaskCardProps) {
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDepCreateModal, setShowDepCreateModal] = useState(false)
    const [showTaskUpdateModal, setShowTaskUpdateModal] = useState(false)

    const updateStatus = () => {
        let arg: TaskStatusUpdateReq = {
            status: "done"
        }

        if (props.task.status === "done") {
            arg.status = "new"
        }

        api.tasksTaskIDStatusPost({
            taskID: props.task.id,
            req: arg,
        }).then(() => {
            props.updateTasks()
        })
    }

    const deleteTask = () => {
        api.tasksTaskIDDelete({
            taskID: props.task.id
        }).then(() => {
            props.updateTasks()
        })
    }

    let taskClass = "TaskCard"
    if (props.isSubtask) {
        taskClass = "ChildTaskCard"
    }

    let linkClass = "Task"
    if (props.task.resolvedAt) {
        linkClass = "ResolvedTask"
    } else if (props.task.hasUnresolvedDeps) {
        linkClass = "BlockedTask"
    }
    return <div className={classNames("BaseTaskCard", taskClass, { "TaskResolved": props.task.resolvedAt })}>
        <Form.Check type="checkbox" readOnly={props.task.hasUnresolvedDeps} disabled={props.task.hasUnresolvedDeps} checked={props.task.status === "done"} onChange={updateStatus} className="TaskCheckbox" />
        <Link className={linkClass} to={`/tasks/${props.task.id}`}>{props.task.parentTitle && !props.isSubtask ? `${props.task.parentTitle} / ${props.task.title}` : props.task.title}
        </Link>
        <div className="PreLine">{props.task.description}</div>
        <div className="TaskListRightBlock">
            <div className="FlexRow">
                <div className="TaskListTimestamp">{props.task.resolvedAt ? `${formatDate(props.task.createdAt)} / ${formatDate(props.task.resolvedAt)}` : formatDate(props.task.createdAt)}</div>
                <div>
                    <Dropdown>
                        <Dropdown.Toggle as={DotsToggle} />
                        <Dropdown.Menu title="">
                            <Dropdown.Item onClick={() => setShowTaskUpdateModal(true)}>Редактировать</Dropdown.Item>
                            {!props.isSubtask && <Dropdown.Item onClick={() => { setShowCreateModal(true); props.updateTasks() }}>Создать подзадачу</Dropdown.Item>}
                            <Dropdown.Item onClick={() => setShowDepCreateModal(true)}>Добавить зависимость</Dropdown.Item>
                            <Dropdown.Item onClick={deleteTask}>Удалить</Dropdown.Item>
                            {props.extraContextMenu && props.extraContextMenu.map((cm) => <Dropdown.Item onClick={() => cm.onClick(props.task)}>{cm.title}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>

        {showCreateModal && <TaskCreateModal
            projectID={props.task.projectID}
            parentID={props.task.id}
            onHide={() => { setShowCreateModal(false); props.updateTasks(); }}
        />}

        {showTaskUpdateModal && <TaskUpdateModal
            task={props.task}
            onHide={() => { setShowTaskUpdateModal(false); props.updateTasks(); }}
        />}

        {showDepCreateModal && <DepCreateModal
            taskId={props.task.id}
            onHide={() => { setShowDepCreateModal(false); props.updateTasks(); }}
        />}

        {props.task.children && props.task.children.length > 0 &&
            <div>
                {props.task.children.map((task, idx) => <TaskCard key={task.id} task={task} updateTasks={props.updateTasks} isSubtask={true} />)}
            </div>}
    </div>
}

type DepCreateModalProps = {
    taskId: number
    onHide: () => void
}

function DepCreateModal(props: DepCreateModalProps) {
    const [options, setOptions] = useState<IntOptionsItem[]>([])
    const { value: depID, bind: bindDepID, setValue: setDepID } = useInput(0)

    useEffect(() => {
        api.depCreateOptions({
            taskID: props.taskId
        }).then((res: IntOptionsItem[]) => {
            setOptions(res)
            if (res.length > 0) {
                setDepID(res[0].value)
            }
        })
    }, [setOptions, setDepID, props.taskId])

    const submit = async (e: any) => {
        e.preventDefault()

        let req: DepCreateRequest = {
            taskID: props.taskId,
            req: {
                depID: Number(depID),
            },
        }

        try {
            await api.depCreate(req)
            alertStore.clearError()
        } catch { }

        props.onHide()
    }

    return <Modal show={true} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Создать задачу</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={submit}>
                <Form.Group>
                    <Form.Label>Зависимость</Form.Label>
                    <Form.Select aria-label="Default select example" {...bindDepID}>
                        {options.map((v) => <option value={v.value}>{v.label}</option>)}
                    </Form.Select>
                </Form.Group>
            </Form>
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={submit} variant="primary">Добавить</Button>
        </Modal.Footer>
    </Modal>
}