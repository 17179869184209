import { makeAutoObservable } from "mobx"

class UserStore {
    uid: number = 0
    email: string = ""
  
    constructor() {
        makeAutoObservable(this)
    }
  
    login(id: number, email: string) {
        this.uid = id
        this.email = email
    }

    logout() {
        this.uid = 0
        this.email = ""
    }
  }

export const userStore = new UserStore()