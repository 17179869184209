/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskStatusUpdateReq
 */
export interface TaskStatusUpdateReq {
    /**
     * 
     * @type {string}
     * @memberof TaskStatusUpdateReq
     */
    status: TaskStatusUpdateReqStatusEnum;
}


/**
 * @export
 */
export const TaskStatusUpdateReqStatusEnum = {
    Done: 'done',
    New: 'new'
} as const;
export type TaskStatusUpdateReqStatusEnum = typeof TaskStatusUpdateReqStatusEnum[keyof typeof TaskStatusUpdateReqStatusEnum];


/**
 * Check if a given object implements the TaskStatusUpdateReq interface.
 */
export function instanceOfTaskStatusUpdateReq(value: object): value is TaskStatusUpdateReq {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function TaskStatusUpdateReqFromJSON(json: any): TaskStatusUpdateReq {
    return TaskStatusUpdateReqFromJSONTyped(json, false);
}

export function TaskStatusUpdateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStatusUpdateReq {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

  export function TaskStatusUpdateReqToJSON(json: any): TaskStatusUpdateReq {
      return TaskStatusUpdateReqToJSONTyped(json, false);
  }

  export function TaskStatusUpdateReqToJSONTyped(value?: TaskStatusUpdateReq | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'status': value['status'],
    };
}

