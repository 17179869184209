/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectCreateResp
 */
export interface ProjectCreateResp {
    /**
     * 
     * @type {number}
     * @memberof ProjectCreateResp
     */
    id: number;
}

/**
 * Check if a given object implements the ProjectCreateResp interface.
 */
export function instanceOfProjectCreateResp(value: object): value is ProjectCreateResp {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function ProjectCreateRespFromJSON(json: any): ProjectCreateResp {
    return ProjectCreateRespFromJSONTyped(json, false);
}

export function ProjectCreateRespFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectCreateResp {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

  export function ProjectCreateRespToJSON(json: any): ProjectCreateResp {
      return ProjectCreateRespToJSONTyped(json, false);
  }

  export function ProjectCreateRespToJSONTyped(value?: ProjectCreateResp | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
    };
}

