/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrentUserPutRequest
 */
export interface CurrentUserPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CurrentUserPutRequest
     */
    email: string;
}

/**
 * Check if a given object implements the CurrentUserPutRequest interface.
 */
export function instanceOfCurrentUserPutRequest(value: object): value is CurrentUserPutRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function CurrentUserPutRequestFromJSON(json: any): CurrentUserPutRequest {
    return CurrentUserPutRequestFromJSONTyped(json, false);
}

export function CurrentUserPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUserPutRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

  export function CurrentUserPutRequestToJSON(json: any): CurrentUserPutRequest {
      return CurrentUserPutRequestToJSONTyped(json, false);
  }

  export function CurrentUserPutRequestToJSONTyped(value?: CurrentUserPutRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
    };
}

