/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectUpdateReq
 */
export interface ProjectUpdateReq {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateReq
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdateReq
     */
    sortOrder: number;
}

/**
 * Check if a given object implements the ProjectUpdateReq interface.
 */
export function instanceOfProjectUpdateReq(value: object): value is ProjectUpdateReq {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('sortOrder' in value) || value['sortOrder'] === undefined) return false;
    return true;
}

export function ProjectUpdateReqFromJSON(json: any): ProjectUpdateReq {
    return ProjectUpdateReqFromJSONTyped(json, false);
}

export function ProjectUpdateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectUpdateReq {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'sortOrder': json['sortOrder'],
    };
}

  export function ProjectUpdateReqToJSON(json: any): ProjectUpdateReq {
      return ProjectUpdateReqToJSONTyped(json, false);
  }

  export function ProjectUpdateReqToJSONTyped(value?: ProjectUpdateReq | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'sortOrder': value['sortOrder'],
    };
}

