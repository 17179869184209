import { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom"

import { api } from '../api'
import { Task, TaskList } from '../zzgen'
import { TaskListBlock } from '../components/TaskListBlock'

export function TodoPage() {
    const [searchParams, ] = useSearchParams()
    const [tasks, setTasks] = useState<Task[]>([])

    const pid = searchParams.get("pid") ? Number(searchParams.get("pid")) : undefined
    const refreshTasks = (pid: number | undefined) => {
        api.todoGet({
            pid: pid,
        }).then((res: TaskList) => {
            setTasks(res.items)
        })
    }

    useEffect(() => {
        refreshTasks(pid)
    }, [setTasks, pid])

    return (<div>
        <TaskListBlock tasks={tasks} updateTasks={() => {refreshTasks(pid)}} />
    </div>)
}