/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
    TaskToJSONTyped,
} from './Task';

/**
 * 
 * @export
 * @interface TaskInfo
 */
export interface TaskInfo {
    /**
     * 
     * @type {Task}
     * @memberof TaskInfo
     */
    task: Task;
    /**
     * 
     * @type {Array<Task>}
     * @memberof TaskInfo
     */
    deps: Array<Task>;
}

/**
 * Check if a given object implements the TaskInfo interface.
 */
export function instanceOfTaskInfo(value: object): value is TaskInfo {
    if (!('task' in value) || value['task'] === undefined) return false;
    if (!('deps' in value) || value['deps'] === undefined) return false;
    return true;
}

export function TaskInfoFromJSON(json: any): TaskInfo {
    return TaskInfoFromJSONTyped(json, false);
}

export function TaskInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'task': TaskFromJSON(json['task']),
        'deps': ((json['deps'] as Array<any>).map(TaskFromJSON)),
    };
}

  export function TaskInfoToJSON(json: any): TaskInfo {
      return TaskInfoToJSONTyped(json, false);
  }

  export function TaskInfoToJSONTyped(value?: TaskInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'task': TaskToJSON(value['task']),
        'deps': ((value['deps'] as Array<any>).map(TaskToJSON)),
    };
}

