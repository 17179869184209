import { useNavigate } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import { useInput } from '../utils'
import { api } from '../api'
import { alertStore } from "../stores/error";

export function RegisterPage() {
  const navigate = useNavigate();
  const { value: email, bind: bindEmail } = useInput('')
  const { value: password, bind: bindPassword } = useInput('')

  const submit = async (e: any) => {
    e.preventDefault()

    try {
      let user = await api.authRegisterPost({
        req: {
          email: email,
          password: password,
        }
      })

      alertStore.clearError()
      alertStore.setSuccess("Проверьте почту и введите проверочный код из нашего письма для того чтобы завершить регистрацию.")
      navigate(`/register-activate/${user.id}`)
    } catch { }
  }

  return (<div style={{ width: "1100px", margin: "auto", paddingTop: "30px" }}>
    <h1>Регистрация</h1>
    <Form onSubmit={submit}>
      <Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control {...bindEmail} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Пароль</Form.Label>
          <Form.Control type="password" {...bindPassword} />
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-3">
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form.Group>
    </Form>

  </div>)
}