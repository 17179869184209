import React, { useState } from "react"

import './utils.css'

export function debug(...args) {
    console.log("DEBUG: ", ...args)
}

export const useInput = (initialValue, callback) => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        setValue,
        reset: () => setValue(""),
        bind: {
            value,
            onChange: event => {
                if (callback) {
                    callback()
                }
                setValue(event.target.value);
            }
        }
    };
};

export const useInputInteger = (initialValue, callback) => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        setValue,
        reset: () => setValue(0),
        bind: {
            value,
            onChange: event => {
                if (callback) {
                    callback()
                }
                const value = event.target.value.replace(/\D/g, "")
                setValue(Number(value));
            }
        }
    };
};

export const useInputDropdownTree = initialValue => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        setValue,
        reset: () => setValue(""),
        bind: {
            onChange: currentNode => {
                if (currentNode.checked) {
                    setValue(currentNode.value)
                } else {
                    setValue("")
                }
            }
        }
    };
};

export const useInputCheckbox = initialValue => {
    const [checked, setChecked] = useState(initialValue);

    return {
        checked,
        setChecked,
        reset: () => setChecked(false),
        bind: {
            checked,
            onChange: event => {
                setChecked(event.target.checked);
            }
        }
    };
};

export function validatePassword(passwd)  {
    if (passwd.length < 8) {
        return false
    }

    let hasLowerCase = /[a-z]+/.test(passwd)
    let hasUpperCase = /[A-Z]+/.test(passwd)
    let hasDigits = /[0-9]+/.test(passwd)
    let hasSpecialChar = /[^A-Za-z0-9]+/.test(passwd)

    return hasLowerCase && hasUpperCase && hasDigits && hasSpecialChar
}

export const DotsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      className="threedots-button"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </button>
  ))