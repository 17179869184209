/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskDepCreateReq
 */
export interface TaskDepCreateReq {
    /**
     * 
     * @type {number}
     * @memberof TaskDepCreateReq
     */
    depID: number;
}

/**
 * Check if a given object implements the TaskDepCreateReq interface.
 */
export function instanceOfTaskDepCreateReq(value: object): value is TaskDepCreateReq {
    if (!('depID' in value) || value['depID'] === undefined) return false;
    return true;
}

export function TaskDepCreateReqFromJSON(json: any): TaskDepCreateReq {
    return TaskDepCreateReqFromJSONTyped(json, false);
}

export function TaskDepCreateReqFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskDepCreateReq {
    if (json == null) {
        return json;
    }
    return {
        
        'depID': json['depID'],
    };
}

  export function TaskDepCreateReqToJSON(json: any): TaskDepCreateReq {
      return TaskDepCreateReqToJSONTyped(json, false);
  }

  export function TaskDepCreateReqToJSONTyped(value?: TaskDepCreateReq | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'depID': value['depID'],
    };
}

