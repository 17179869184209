import { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite"
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom"
import Container from 'react-bootstrap/Container'
import { api } from './api'
import { alertStore } from "./stores/error"
import { projectStore } from "./stores/project"
import { screen } from './stores/screen'
import { TopPanel } from './components/TopPanel'
import { RegisterPage } from './pages/Register'
import { LoginPage } from './pages/Login'
import { RegisterActivatePage } from './pages/RegisterActivate'
import { ResetPasswordPage } from './pages/ResetPassword'
import { ResetPasswordInitPage } from './pages/ResetPasswordInit'
import { ProfileEditPage } from './pages/ProfileEdit'
import { TasksPage } from './pages/Tasks'
import { TaskPage } from './pages/Task'
import { TodoPage } from './pages/Todo'
import { ProjectsPage } from './pages/Projects'
import { AlertDismissible } from './components/AlertDismissible'

import { ResponseError } from './zzgen';

import { userStore } from "./stores/user"
import './App.css'


async function updateToken() {
  try {
    let user = await api.authRefreshPost()
    userStore.login(user.id, user.email)
    setTimeout(() => { updateToken() }, 1000 * (user.maxAge - 60))
  } catch (e) {
    if (e instanceof ResponseError) {
      let err = e as ResponseError
      if (err.response.status === 401) {
        alertStore.clearError()
      } else {
      }
    }

    throw(e)
  }
}

async function getInfo(finishCallback: () => void) {
  try {
    await updateToken()
  } catch (e) {
    finishCallback()
    return
  }

  const projects = await api.projectList()
  projectStore.setProjects(projects.items)
  finishCallback()
}

export const AppHome = observer(function AppHome() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    getInfo(() => {
      setLoaded(true)
    })
  }, [])

  const updateWindowDimensions = () => {
    screen.setDimensions(window.innerWidth, window.innerHeight, window.devicePixelRatio)
  }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  if (loaded === false) {
    return null
  }

  if (!userStore.uid) {
    return <LoginPage />
  }

  return (
    <Container >
      <TopPanel />
      <AlertDismissible />
      <div style={{ marginTop: "10px" }}>
        <Outlet />
      </div>
    </Container>
  );
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppHome />,
    children: [
      {
        index: true,
        element: <TasksPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register-activate/:uid",
        element: <RegisterActivatePage />,
      },
      {
        path: "reset-password-init",
        element: <ResetPasswordInitPage />,
      },
      {
        path: "reset-password/:code",
        element: <ResetPasswordPage />,
      },
      {
        path: "profile",
        element: <ProfileEditPage />,
      },
      {
        path: "tasks/:taskID",
        element: <TaskPage />,
      },
      {
        path: "todo",
        element: <TodoPage />,
      },
      {
        path: "projects",
        element: <ProjectsPage />,
      },
    ]
  },
])

export function App() {
  return <RouterProvider router={router} />
}