import { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useSearchParams } from "react-router-dom"

import { api } from '../api'
import { useInputCheckbox } from '../utils'
import { Task, TaskList } from '../zzgen'
import { TaskCreateModal } from '../components/TaskCreateModal'
import { TaskListBlock } from '../components/TaskListBlock'
import "./Tasks.css"

export function TasksPage() {
    const [searchParams, ] = useSearchParams()
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [tasks, setTasks] = useState<Task[]>([])
    const { checked: hideResolvedBeforeEnabled, bind: bindhideResolvedBefore } = useInputCheckbox(true)

    const pid = searchParams.get("pid") ? Number(searchParams.get("pid")) : undefined

    const refreshTasks = (pid: number|undefined, hideResolvedBeforeEnabled: boolean) => {
        let hideResolvedBefore = undefined
        if (hideResolvedBeforeEnabled) {
            hideResolvedBefore = new Date()
            hideResolvedBefore.setDate(hideResolvedBefore.getDate() - 5);
        }
        api.tasksGet({
            pid: pid,
            hideResolvedBefore: hideResolvedBefore,
        }).then((res: TaskList) => {
            setTasks(res.items)
        })
    }

    useEffect(() => {
        refreshTasks(pid, hideResolvedBeforeEnabled)
    }, [setTasks, pid, hideResolvedBeforeEnabled])

    return (<div>
        <Button onClick={() => { setShowCreateModal(true) }}>Создать</Button>
        <Form.Check
            {...bindhideResolvedBefore}
            type="checkbox"
            label="Скрыть завершенные 2 недели назад"
        />
        <></>

        {showCreateModal &&
            <TaskCreateModal projectID={pid} onHide={() => { setShowCreateModal(false); refreshTasks(pid, hideResolvedBeforeEnabled); }}
            />
        }

        <TaskListBlock tasks={tasks} updateTasks={() => {refreshTasks(pid, hideResolvedBeforeEnabled)}} />
    </div>)
}