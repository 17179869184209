/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthLoginPostRequest
 */
export interface AuthLoginPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginPostRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginPostRequest
     */
    password?: string;
}

/**
 * Check if a given object implements the AuthLoginPostRequest interface.
 */
export function instanceOfAuthLoginPostRequest(value: object): value is AuthLoginPostRequest {
    return true;
}

export function AuthLoginPostRequestFromJSON(json: any): AuthLoginPostRequest {
    return AuthLoginPostRequestFromJSONTyped(json, false);
}

export function AuthLoginPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthLoginPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'password': json['password'] == null ? undefined : json['password'],
    };
}

  export function AuthLoginPostRequestToJSON(json: any): AuthLoginPostRequest {
      return AuthLoginPostRequestToJSONTyped(json, false);
  }

  export function AuthLoginPostRequestToJSONTyped(value?: AuthLoginPostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

