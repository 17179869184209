import { makeAutoObservable } from "mobx"

class AlertStore {
    error: string|null = null
    success: string|null = null
  
    constructor() {
        makeAutoObservable(this)
    }
  
    setError(err: string) {
        this.error = err
    }

    setSuccess(info: string) {
        this.success = info
    }

    clearError() {
        this.error = null
    }

    clearSuccess() {
        this.success = null
    }
  }

export const alertStore = new AlertStore()