/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthChangePasswordPostRequest
 */
export interface AuthChangePasswordPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordPostRequest
     */
    currentPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordPostRequest
     */
    newPassword?: string;
}

/**
 * Check if a given object implements the AuthChangePasswordPostRequest interface.
 */
export function instanceOfAuthChangePasswordPostRequest(value: object): value is AuthChangePasswordPostRequest {
    return true;
}

export function AuthChangePasswordPostRequestFromJSON(json: any): AuthChangePasswordPostRequest {
    return AuthChangePasswordPostRequestFromJSONTyped(json, false);
}

export function AuthChangePasswordPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthChangePasswordPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'currentPassword': json['currentPassword'] == null ? undefined : json['currentPassword'],
        'newPassword': json['newPassword'] == null ? undefined : json['newPassword'],
    };
}

  export function AuthChangePasswordPostRequestToJSON(json: any): AuthChangePasswordPostRequest {
      return AuthChangePasswordPostRequestToJSONTyped(json, false);
  }

  export function AuthChangePasswordPostRequestToJSONTyped(value?: AuthChangePasswordPostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'currentPassword': value['currentPassword'],
        'newPassword': value['newPassword'],
    };
}

