import { useState, useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'

import { api } from '../api'
import { useInput } from '../utils'
import { alertStore } from "../stores/error"
import { Project, ProjectList, TaskCreateReq } from '../zzgen'

type TaskCreateModalProps = {
    projectID?: number
    parentID?: number
    onHide: () => void
}

export function TaskCreateModal(props: TaskCreateModalProps) {
    const [projects, setProjects] = useState<Project[]>([])
    const { value: title, bind: bindTitle } = useInput("")
    const { value: description, bind: bindDescription } = useInput("")
    const { value: date, bind: bindDate } = useInput("")
    const { value: project, bind: bindProject, setValue: setProject } = useInput(props.projectID)

    useEffect(() => {
        api.projectList().then((res: ProjectList) => {
            setProjects(res.items)
            if (!props.projectID) {
                setProject(res.items[0].id)
            }
        })
    }, [setProjects, setProject, props.projectID])

    const submit = async (e: any) => {
        e.preventDefault()

        let req: TaskCreateReq = {
            title: title,
            description: description,
            parentID: props.parentID,
            projectID: project,
        }

        if (date !== null && date !== "") {
            req.startsAt = new Date(date)
        }

        try {
            await api.tasksPost({
                req: req,
            })

            alertStore.clearError()
        } catch { }

        props.onHide()
    }

    return <Modal show={true} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Создать задачу</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={submit}>
                <Form.Group className="mb-2">
                    <Form.Label>Заголовок</Form.Label>
                    <Form.Control {...bindTitle} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control as="textarea" rows={3} {...bindDescription} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Дата начала</Form.Label>
                    <Form.Control type="date" {...bindDate} />
                </Form.Group>
                {!props.parentID && <Form.Group>
                    <Form.Label htmlFor="projectSelect">Проект</Form.Label>
                    <Form.Select id="projectSelect" {...bindProject} >
                        {projects.map((p) => <option key={p.id} value={p.id}>{p.title}</option>)}
                    </Form.Select>
                </Form.Group>}

            </Form>
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={submit} variant="primary">Создать</Button>
        </Modal.Footer>
    </Modal>
}