import { makeAutoObservable } from "mobx"

import { Project } from '../zzgen'
class ProjectStore {
    projects: Project[] = []
  
    constructor() {
        makeAutoObservable(this)
    }
  
    setProjects(projects: Project[]) {
        this.projects = projects
    }
  }

export const projectStore = new ProjectStore()