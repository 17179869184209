/* tslint:disable */
/* eslint-disable */
/**
 * TaskList API
 * TaskList API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Project } from './Project';
import {
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
    ProjectToJSONTyped,
} from './Project';

/**
 * 
 * @export
 * @interface ProjectList
 */
export interface ProjectList {
    /**
     * 
     * @type {Array<Project>}
     * @memberof ProjectList
     */
    items: Array<Project>;
}

/**
 * Check if a given object implements the ProjectList interface.
 */
export function instanceOfProjectList(value: object): value is ProjectList {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function ProjectListFromJSON(json: any): ProjectList {
    return ProjectListFromJSONTyped(json, false);
}

export function ProjectListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectList {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ProjectFromJSON)),
    };
}

  export function ProjectListToJSON(json: any): ProjectList {
      return ProjectListToJSONTyped(json, false);
  }

  export function ProjectListToJSONTyped(value?: ProjectList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'items': ((value['items'] as Array<any>).map(ProjectToJSON)),
    };
}

