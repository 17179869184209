import { Button, Modal, Form } from 'react-bootstrap'

import { api } from '../api'
import { useInput } from '../utils'
import { alertStore } from "../stores/error"
import { Task, TaskUpdateReq } from '../zzgen'

type TaskUpdateModalProps = {
    task: Task
    onHide: () => void
}

export function TaskUpdateModal(props: TaskUpdateModalProps) {
    const task = props.task
    const { value: title, bind: bindTitle } = useInput(task.title)
    const { value: description, bind: bindDescription } = useInput(task.description)
    const { value: date, bind: bindDate } = useInput(task.startsAt ? task.startsAt.toISOString().split('T')[0] : "")

    const submit = async (e: any) => {
        e.preventDefault()

        let req: TaskUpdateReq = {
            title: title,
            description: description,
        }

        if (date !== null && date !== "") {
            req.startsAt = new Date(date)
        }

        try {
            await api.taskUpdate({
                taskID: task.id,
                req: req,
            })

            alertStore.clearError()
        } catch (e) { alert(e) }

        props.onHide()
    }

    return <Modal show={true} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Редактировать задачу</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={submit}>
                <Form.Group className="mb-2">
                    <Form.Label>Заголовок</Form.Label>
                    <Form.Control {...bindTitle} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control as="textarea" rows={3} {...bindDescription} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Дата начала</Form.Label>
                    <Form.Control type="date" {...bindDate} />
                </Form.Group>
            </Form>
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={submit} variant="primary">Сохранить</Button>
        </Modal.Footer>
    </Modal>
}