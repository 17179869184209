import { useNavigate, createSearchParams } from "react-router-dom"
import { Button, Form } from 'react-bootstrap'
import { useInput } from '../utils'
import { api } from '../api'
import { alertStore } from "../stores/error"
import { userStore } from "../stores/user"
import { projectStore } from "../stores/project"

export function LoginPage() {
  const navigate = useNavigate()
  const { value: email, bind: bindEmail } = useInput('')
  const { value: password, bind: bindPassword } = useInput('')

  const submit = async (e: any) => {
    e.preventDefault()

    try {
      let user = await api.authLoginPost({
        req: {
          email: email,
          password: password,
        }
      })

      alertStore.clearError()
      userStore.login(user.id, user.email)

      const projects = await api.projectList()
      projectStore.setProjects(projects.items)
      navigate({
        pathname: '/',
        search: createSearchParams({
          pid: String(projects.items[0].id),
      }).toString()
      })
    } catch { }
  }

  return (<div style={{ width: "1100px", margin: "auto", paddingTop: "30px" }}>
    <h1>Вход</h1>
    <Form onSubmit={submit}>
      <Form.Group >
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control {...bindEmail} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Пароль</Form.Label>
          <Form.Control type="password" {...bindPassword} />
        </Form.Group>
      </Form.Group>
      <Form.Group>
        <Button variant="primary" type="submit">
          Войти
        </Button>
      </Form.Group>
    </Form><br />
    <a href="/reset-password-init">Забыли пароль?</a>
  </div>)
}