import { observer } from "mobx-react-lite"
import { Alert } from 'react-bootstrap';
import { alertStore } from '../stores/error'

export const AlertDismissible = observer(function AlertDismissible() {
    return <><ErrorDismissible /><InfoDismissible /></>
}
);

const ErrorDismissible = observer(function ErrorDismissible() {
    return alertStore.error !== null ?
        <Alert variant="danger" onClose={() => alertStore.clearError()} dismissible>
            {alertStore.error}
        </Alert> :
        <></>
}
);

const InfoDismissible = observer(function InfoDismissible() {
    return alertStore.success !== null ?
        <Alert variant="success" onClose={() => alertStore.clearSuccess()} dismissible>
            {alertStore.success}
        </Alert> :
        <></>
}
);