// Copyright (C) Dmitry Guryanov - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
// Written by Dmitry Guryanov <dmitry.guryanov@gmail.com>, 2017-2019

import { makeObservable, action, observable, computed } from 'mobx'

class ScreenStore {
    width: number = 0
    height: number = 0
    pixelRatio: number = 0

    constructor() {
        makeObservable(this, {
            width: observable,
            height: observable,
            pixelRatio: observable,
            setDimensions: action,
            pixelWidth: computed,
            pixelHeight: computed,
        })
    }

    setDimensions = (width: number, height: number, pixelRatio: number) => {
        this.width = width
        this.height = height
        this.pixelRatio = pixelRatio
    }

    get pixelWidth() {
        return Math.floor(this.width * this.pixelRatio)
    }

    get pixelHeight() {
        return Math.floor(this.height * this.pixelRatio)
    }
}

export const screen = new ScreenStore()